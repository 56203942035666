import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import WithAnimation from '@components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgSize="cover"
      padding="42px"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <WithAnimation>
        <Text letterSpacing="4px" fontSize="xs">
          THE WEDDING OF
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Heading
          margin="32px 0"
          fontWeight="normal"
          size="4xl"
          letterSpacing="2px"
          lineHeight="80%"
          dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
        />
      </WithAnimation>
      <WithAnimation>
        <Text fontStyle="italic">
          “I found you without looking, and love you without trying” <br />– Mark Anthony
        </Text>
      </WithAnimation>
    </Box>
  );
}

export default LeftContent;
